import {
  Campaign,
  ClickAction,
  ConditionalTargetRLs,
  MessageButton,
  MessageTemplate,
  TrackingField,
} from "../types/campaign";
import { FilterModel, NumberFilterModel, SortModel } from "../types/query";
import { UseFormMethods } from "react-hook-form";
import { compareAsc, endOfDay } from "date-fns";
import {
  concatIsoDate,
  createCurrentDateWithTimezone,
  createDateWithTimezone,
  formatIsoUtcDate,
  getTimezoneName,
  parseDateTimeString,
} from "./date";
import { difference } from "./channelServices";
import { forOwn, isArray, isEmpty, isEqual, isObject, remove } from "lodash";
import { registerField } from "./forms";
import _ from "lodash";
import datejsTZ from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import startOfDay from "date-fns/startOfDay";
import utc from "dayjs/plugin/utc";

export const getChannelFromSegment = (payload?: object) => {
  const payloadJson = payload ? JSON.parse(JSON.stringify(payload)) : {};
  if (
    payloadJson.channel_ids &&
    isArray(payloadJson.channel_ids) &&
    payloadJson.channel_ids[0]
  ) {
    return payloadJson.channel_ids[0];
  }
  return null;
};

export const getChannelIdsFromSegment = (payload?: object) => {
  const payloadJson = payload ? JSON.parse(JSON.stringify(payload)) : {};
  if (payloadJson.channel_ids && isArray(payloadJson.channel_ids)) {
    return payloadJson.channel_ids;
  }
  return [];
};

export const createPayloadFromChannel = (channelId?: number) => {
  const payload_json = {
    channel_ids: [channelId],
    elements: [],
  };
  return payload_json;
};

const removeEmptyValue = (object: { [key: string]: any }) => {
  forOwn(object, (value, key) => {
    if ((isObject(value) && isEmpty(value)) || value === "") {
      delete object[key];
    }
  });
  if (object.tracking_field_names && object.tracking_field_names.length > 0) {
    remove(object.tracking_field_names, (field: TrackingField) => {
      if (field.tracker_func_name === "" && field.name === "") {
        return true;
      }
      return false;
    });
  }

  return object;
};

export const provideDefaultValue = (
  object: Campaign,
  base: Campaign,
  isDraft: boolean
): Campaign => {
  const result = {
    ...object,
    start_time: calculateStartTime(object, base, isDraft),
    is_draft: base?.is_draft === isDraft ? undefined : isDraft,
    is_active: !isDraft,
    timezone:
      !object.timezone?.name && !base.timezone?.name
        ? getTimezoneName()
        : object.timezone?.name,
    target_url: undefined,
    tracking_field_names: undefined,
    template_id: undefined,
    triggered_delivery:
      object.triggered_delivery === undefined &&
      base.triggered_delivery !== undefined
        ? undefined
        : object.triggered_delivery,
  } as any;
  Object.keys(result).forEach(
    (key) => result[key] === undefined && delete result[key]
  );
  return result;
};

export const makePureCampaign = (
  object: Campaign,
  base: Campaign | undefined
) => {
  if (base) {
    const diffObject = difference(object, base);
    // diffObject.start_time = object.start_time;
    const result = removeEmptyValue(diffObject);
    if (result.triggered_delivery) {
      let tmpObj = result.triggered_delivery;

      if (
        result.triggered_delivery.cron === undefined ||
        result.triggered_delivery.cron === null ||
        result.triggered_delivery.cron.length === 0
      ) {
        delete tmpObj["cron"];
      } else {
        let cronData = result.triggered_delivery.cron.map((item: any) => {
          let dateNow = new Date();
          dateNow.setUTCHours(0);
          dateNow.setUTCMinutes(0);
          dayjs.extend(utc);
          dayjs.extend(datejsTZ);
          var now = dayjs(dateNow)
            .tz(object?.timezone?.name)
            .set("hour", item.hour)
            .set("minute", item.minute)
            .set("second", 0);
          let date = item.day_of_week + (now.utc().date() - now.date());
          return {
            ...item,
            day_of_week:
              date === 0
                ? 0
                : date !== null && date !== undefined && !!date
                ? date < 0
                  ? 6
                  : date > 6
                  ? 0
                  : date
                : undefined,
            hour: now.utc().hour(),
            minute: now.utc().minute(),
          };
        });
        result.triggered_delivery.cron = cronData;
      }
      if (
        result.triggered_delivery.event === undefined ||
        result.triggered_delivery.event === null ||
        result.triggered_delivery.event.length === 0
      ) {
        delete tmpObj["event"];
      }
      if (
        result.triggered_delivery.interval === undefined ||
        result.triggered_delivery.interval === null ||
        isEmpty(result.triggered_delivery.interval)
      ) {
        delete tmpObj["interval"];
      }
      if (
        result.triggered_delivery.message_instance === undefined ||
        result.triggered_delivery.message_instance === null ||
        isEmpty(result.triggered_delivery.message_instance)
      ) {
        delete tmpObj["message_instance"];
      }
      if (
        result.triggered_delivery.one_time === undefined ||
        result.triggered_delivery.one_time === null ||
        result.triggered_delivery.one_time.length === 0
      ) {
        delete tmpObj["one_time"];
      }
      if (
        result.triggered_delivery.pool === undefined ||
        result.triggered_delivery.pool === null
      ) {
        delete tmpObj["pool"];
      }
      if (
        result.triggered_delivery.start_time === undefined ||
        result.triggered_delivery.start_time === null ||
        result.triggered_delivery.start_time.length === 0
      ) {
        delete tmpObj["start_time"];
      }

      result.triggered_delivery = tmpObj;
    } else {
      if (base.triggered_delivery && !object.triggered_delivery) {
        result.triggered_delivery = {};
      }
    }
    if (object?.interval && object?.interval?.every === undefined) {
      if (base.interval && object?.is_active) {
        result.interval = null;
      } else {
        delete result.interval;
      }
    }
    if (object.target_url === "") {
      result.target_url = "";
    }
    return result;
  }
  return object;
};

export const makeTemplateChange = (
  {
    template_id,
    target_url,
    tracking_field_names,
    message_button,
    conditional_target_URLs,
  }: Campaign,
  template?: MessageTemplate,
  channelId?: number,
  campaignId?: number
): MessageTemplate | null => {
  let tmpconditional_target_URLs = conditional_target_URLs
    ? conditional_target_URLs.map((item) => {
        const subAgeRangeValue = (item.conditional_val ?? "-").split("-");
        const formVal = subAgeRangeValue[0]
          ? Number(subAgeRangeValue[0]) - 1
          : "";
        const toVal =
          subAgeRangeValue.length > 1
            ? subAgeRangeValue[1]
              ? Number(subAgeRangeValue[1]) - 1
              : ""
            : "";
        return item.conditional_func === "sub_age_range_verification"
          ? { ...item, conditional_val: `${formVal}-${toVal}` }
          : item;
      })
    : undefined;
  let isButtonlChanged = false;
  let isConditionalTargetURLsChanged = false;
  let targetUrl = template?.click_actions?.find(
    (item) =>
      !item.title &&
      !item.conditional_func &&
      item.click_action === "url" &&
      !!item.click_action_data
  );

  const isUrlChanged = targetUrl?.click_action_data !== target_url;
  isButtonlChanged =
    isButtonlChanged ||
    message_button?.length !== template?.click_actions?.length;

  if (!!message_button && !!template && !!template.click_actions) {
    isButtonlChanged =
      isButtonlChanged ||
      message_button.length + 1 !== template?.click_actions?.length;
    template.click_actions.forEach(
      (item, index) =>
        (isButtonlChanged =
          isButtonlChanged ||
          (!!item.title &&
            !(
              !!message_button[index - 1] &&
              item.click_action_data ===
                message_button[index - 1].click_action_data &&
              item.title === message_button[index - 1].title
            )))
    );
  }

  if (!!tmpconditional_target_URLs && !!template && !!template.click_actions) {
    template.click_actions.forEach(
      (item, index) =>
        (isConditionalTargetURLsChanged =
          isConditionalTargetURLsChanged ||
          (!item.click_action &&
            !(
              !!tmpconditional_target_URLs[index] &&
              item.click_action_data ===
                tmpconditional_target_URLs[index].click_action_data &&
              item.conditional_func ===
                tmpconditional_target_URLs[index].conditional_func &&
              item.conditional_val ===
                tmpconditional_target_URLs[index].conditional_val &&
              item.conditional_args_json ===
                tmpconditional_target_URLs[index].conditional_args_json
            )))
    );
  }

  const isTrackingChanged = !isEqual(
    template?.tracking_field_names ?? [],
    tracking_field_names ?? []
  );
  if (
    isUrlChanged ||
    isTrackingChanged ||
    isButtonlChanged ||
    isConditionalTargetURLsChanged
  ) {
    return {
      id: template_id ?? template?.id,
      campaign_id: template_id ? undefined : campaignId,
      click_actions:
        isUrlChanged || isButtonlChanged || isConditionalTargetURLsChanged
          ? [
              {
                title: undefined,
                click_action: "url",
                click_action_data: target_url ? target_url.trim() : "",
              },
              ...(message_button ?? []),
              ...((tmpconditional_target_URLs?.filter(
                (item) => !!item.click_action_data && !!item.conditional_func
              ) ?? []) as Array<ClickAction>),
            ]
          : undefined,
      tracking_field_names: isTrackingChanged
        ? tracking_field_names?.map(
            ({ name, tracker_func_name, source_link_param }) => ({
              name: name.trim(),
              tracker_func_name,
              source_link_param,
            })
          ) ?? []
        : undefined,
    };
  }
  return null;
};

export const registerCampaignFields = (
  data: Campaign,
  form: UseFormMethods<Record<string, any>>
) => {
  const channel_id = data?.channel?.id;
  form.reset(data);
  if (!!data?.channel) {
    registerField(form, "isDatabase", "database");
  } else if (!!data.segment) {
    registerField(form, "isDatabase", "segment");
  }
  registerField(form, "channel.name", data?.channel?.name);
  registerField(form, "channel.id", data?.channel?.id);
  registerField(form, "segment.name", data.segment?.name);
  registerField(form, "segment.id", data.segment?.id);

  registerField(form, "segment.payload_json", data.segment?.payload_json);
  registerField(form, "channel_id", channel_id);
  registerField(form, "segment_id", data.segment?.id);

  registerField(form, "campaign_id", data.campaign_id);
  registerField(form, "id", data.id);
  registerField(form, "start_time", data.start_time);
  registerField(form, "interval.period", data?.interval?.period ?? "minutes");

  registerField(form, "messages_per_user", data.messages_per_user);
  registerField(form, "message_pool", data.message_pool?.id);
  registerField(form, "message_pool_name", data.message_pool?.name);
  registerField(form, "confirm_name", "");
  registerField(form, "cancel_name", "");
  registerField(form, "confirm_link", "");
  registerField(form, "cancel_link", "");
  registerField(form, "timezone", data.timezone);
  if (
    !!data?.triggered_delivery?.system_event &&
    data?.triggered_delivery?.system_event ===
      "nashpush_system_subscription_event"
  ) {
    data.delivery_type = "event";
    data.triggered_delivery.event_id = "nashpush_system_subscription_event";
  } else if (!!data?.triggered_delivery) {
    data.triggered_delivery.system_event = "";
  }

  registerField(form, "triggered_delivery", data.triggered_delivery ?? {});
  registerField(form, "delivery_type", data.delivery_type ?? {});
  let timeEvent = null;
  let period_event = "minutes";
  if (!!data.triggered_delivery?.start_time_delta) {
    if (data.triggered_delivery.start_time_delta % 86400 === 0) {
      period_event = "days";
      timeEvent = data.triggered_delivery.start_time_delta / 86400;
    } else if (data.triggered_delivery.start_time_delta % 3600 === 0) {
      period_event = "hours";
      timeEvent = data.triggered_delivery.start_time_delta / 3600;
    } else if (data.triggered_delivery.start_time_delta % 60 === 0) {
      period_event = "minutes";
      timeEvent = data.triggered_delivery.start_time_delta / 60;
    }
  } else {
  }
  registerField(form, "event_period", period_event);
  registerField(form, "event_value", timeEvent);
  registerField(form, "has_optimised_items", data.has_optimised_items);
  registerField(form, "iter_cycle", data.iter_cycle);
};

export const registerTemplateFields = (
  form: UseFormMethods<Record<string, any>>,
  template?: MessageTemplate
) => {
  registerField(form, "template_id", template?.id);
  let targetUrl = template?.click_actions?.find(
    (item) =>
      !item.title &&
      !item.conditional_func &&
      item.click_action === "url" &&
      !!item.click_action_data
  );
  registerField(
    form,
    "target_url",
    targetUrl ? targetUrl.click_action_data : ""
  );
  if (
    !!template &&
    template.tracking_field_names?.length === 1 &&
    !template.tracking_field_names[0].source_link_param &&
    !template.tracking_field_names[0].tracker_func_name
  ) {
    registerField(form, "tracking_field_names", []);
  } else {
    registerField(form, "tracking_field_names", template?.tracking_field_names);
  }
  registerMessageButtonFields(form, template);
};

export const calculateStartTime = (
  object: Campaign,
  base: Campaign,
  isDraft: boolean
) => {
  const { start_time, timezone } = object;
  const isStartTimeChanged =
    start_time !== base.start_time ||
    (start_time === null && base.start_time === null);
  if (isDraft || (!isStartTimeChanged && !timezone)) {
    return undefined;
  } else if (start_time) {
    if (timezone) {
      if (isStartTimeChanged) {
        return createDateWithTimezone(start_time, timezone.name).toISOString();
      } else {
        const oldStartTime = createDateWithTimezone(
          start_time,
          base?.timezone?.name ?? timezone.name ?? ""
        );
        return concatIsoDate(oldStartTime, timezone.utcOffsetStr);
      }
    } else {
      if (isStartTimeChanged && base?.timezone?.name) {
        return createDateWithTimezone(
          start_time,
          base?.timezone?.name
        ).toISOString();
      } else {
        return new Date(start_time).toISOString();
      }
    }
  } else {
    return new Date().toISOString();
  }
};

export const calculateOneTime = (object: Campaign, base: Campaign) => {
  const { triggered_delivery, timezone } = object;

  if (triggered_delivery?.one_time) {
    dayjs.extend(utc);
    dayjs.extend(datejsTZ);

    if (!!timezone || !!base?.timezone) {
      var now = dayjs(triggered_delivery?.one_time)
        .tz(timezone?.name ?? base?.timezone?.name)
        .set("hour", dayjs(triggered_delivery?.one_time).utc().get("hour"))
        .set("minute", dayjs(triggered_delivery?.one_time).utc().get("minute"))
        .set("second", 0);
      return now.toISOString();
    } else {
      return new Date(triggered_delivery?.one_time).toISOString();
    }
  } else {
    return new Date().toISOString();
  }
};

export const checkOneTimeValidate = (object: Campaign, base: Campaign) => {
  const { triggered_delivery, timezone } = object;

  if (triggered_delivery?.one_time) {
    if (!!timezone?.name || !!base?.timezone?.name) {
      let minDate = createCurrentDateWithTimezone(
        timezone?.name ?? base?.timezone?.name ?? ""
      );
      if (compareAsc(new Date(triggered_delivery?.one_time), minDate) !== 0) {
        if (
          compareAsc(
            new Date(triggered_delivery?.one_time),
            minDate ?? new Date()
          ) >= 0
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } else {
      if (
        compareAsc(new Date(triggered_delivery?.one_time), new Date()) !== 0
      ) {
        if (
          compareAsc(new Date(triggered_delivery?.one_time), new Date()) >= 0
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
  return false;
};

export const calculateEventTime = (object: Campaign) => {
  const { event_value, event_period } = object;
  if (!event_value) {
    return null;
  }
  if (event_period === "minutes") {
    return event_value * 60;
  } else if (event_period === "hours") {
    return event_value * 3600;
  } else {
    return event_value * 86400;
  }
};

export const checkChange = (
  campaign: Campaign,
  originCampaign: Campaign,
  template: MessageTemplate | undefined,
  channel_id: number | undefined,
  isDraft: boolean
) => {
  if (originCampaign) {
    const pureCampaign = makePureCampaign(campaign, originCampaign) as Campaign;
    const newTemplate = makeTemplateChange(
      pureCampaign,
      template,
      channel_id,
      originCampaign?.id ?? originCampaign?.campaign_id
    );
    const newCampaign = provideDefaultValue(
      pureCampaign,
      originCampaign,
      isDraft
    );

    return { newTemplate, newCampaign };
  }
  return null;
};

export const checkChangeSave = (
  campaign: Campaign,
  originCampaign: Campaign,
  template: MessageTemplate | undefined,
  channel_id: number | undefined,
  isDraft: boolean
) => {
  const valueCheck = checkChange(
    campaign,
    originCampaign,
    template,
    channel_id,
    isDraft
  );
  if (valueCheck) {
    const hasChange =
      !!valueCheck.newTemplate || !isEmpty(valueCheck.newCampaign);
    return { ...valueCheck, hasChange };
  }
  return null;
};
export const checkChangeCancel = (
  campaign: Campaign,
  originCampaign: Campaign,
  template: MessageTemplate | undefined,
  channel_id: number | undefined,
  isDraft: boolean
) => {
  const valueCheck = checkChange(
    campaign,
    originCampaign,
    template,
    channel_id,
    isDraft
  );
  if (valueCheck) {
    const tmpCampaign = valueCheck.newCampaign;
    if (tmpCampaign) {
      delete tmpCampaign.start_time;
      delete tmpCampaign.is_draft;
      delete tmpCampaign.message_button;
    }

    const hasChange = !!valueCheck.newTemplate || !isEmpty(tmpCampaign);
    return { ...valueCheck, hasChange };
  }

  return null;
};

export function convertCustomizeModel(filterModel: FilterModel) {
  const result = {} as FilterModel;
  const tmpFilterModel = { ...filterModel };
  _.forEach(tmpFilterModel, (model, key) => {
    if (model.filterType === "date" && key !== "date") {
      result[key] = {
        ...model,
        dateFrom: model.dateFrom
          ? formatIsoUtcDate(startOfDay(parseDateTimeString(model.dateFrom)))
          : model.dateFrom,
        dateTo: model.dateTo
          ? formatIsoUtcDate(endOfDay(parseDateTimeString(model.dateTo)))
          : model.dateTo,
      };
    } else if (key === "campaign_name") {
      result["search_name"] = model;
    } else if (key === "message_pool") {
      result["message_pools"] = model;
    } else if (key === "interval") {
      if ((model as NumberFilterModel).type === "inRange") {
        result[key] = {
          ...model,
          filter: ((model as NumberFilterModel)["filter"] as number) * 60,
          filterTo: ((model as NumberFilterModel)["filterTo"] as number) * 60,
        } as NumberFilterModel;
      } else {
        result[key] = {
          ...model,
          filter: ((model as NumberFilterModel)["filter"] as number) * 60,
        } as NumberFilterModel;
      }
    } else result[key] = model;
  });
  return result;
}

export function convertCustomizeSortModel(sortModel: SortModel[]) {
  sortModel.map((model) => {
    if (model?.colId === "interval") {
      model.colId = "interval_every";
    } else if (model?.colId === "message_pool") {
      model.colId = "message_pool_name";
    }
    return model;
  });
  return sortModel;
}

export function convertCustomizeSortModelAnalaytics(sortModel: SortModel[]) {
  sortModel.map((model) => {
    if (model?.colId === "segment") {
      model.colId = "segment";
    }
    return model;
  });
  return sortModel;
}

export const registerMessageButtonFields = (
  form: UseFormMethods<Record<string, any>>,
  template?: MessageTemplate
) => {
  let res: MessageButton[] = [];
  let res2: ConditionalTargetRLs[] = [];
  if (template && template.click_actions) {
    template.click_actions.forEach((item) =>
      !!item.title
        ? res.push({
            title: item.title,
            click_action: item.click_action ?? "url",
            click_action_data: item.click_action_data,
          })
        : !!item.conditional_func &&
          res2.push({
            click_action: item.click_action ?? "url",
            conditional_func: item.conditional_func,
            conditional_val: item.conditional_val,
            click_action_data: item.click_action_data,
            conditional_args_json: item.conditional_args_json,
          })
    );
  }
  registerField(form, "message_button", res);
  registerField(
    form,
    "conditional_target_URLs",
    res2.length > 0
      ? res2
      : [
          {
            conditional_func: null,
            conditional_val: null,
            conditional_args_json: null,
            click_action_data: "",
            click_action: "url",
          },
        ]
  );
};

export function convertNumtoDate(num: number) {
  switch (num) {
    case 0:
      return "Mon";
    case 1:
      return "Tue";
    case 2:
      return "Wed";
    case 3:
      return "Thu";
    case 4:
      return "Fri";
    case 5:
      return "Sat";
    case 6:
      return "Sun";
    default:
      return "Every day";
  }
}
